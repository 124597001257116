import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact us"
      description="Have a question? We’d love to hear from you. Send us a message and we’ll respond as soon as possible."
    />
    <section>
      <div className="container">
        <h1 className="section-heading">Contact Us</h1>
        <div className="row">
          <div className="col-lg-6 mx-auto mb-5 text-center">
            <p className="pb-4">
              Have a question? We’d love to hear from you. Send us a message and
              we’ll respond as soon as possible.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="mb-gutter col-sm-6 mb-5 mb-lg-0 col-lg-3 text-center">
            <i className="fas mb-3 fa-2x fa-phone"></i>
            <h5>Phone Number</h5>
            <a href="tel:+021 141 6486">021 141 6486</a>
          </div>
          <div className="mb-gutter col-sm-6 mb-5 mb-lg-0 col-lg-3 text-center">
            <i className="fas mb-3 fa-2x fa-envelope"></i>
            <h5>Email</h5>
            <a href="mailto:admin@lakesidelifestyle.co.nz">
              admin@lakesidelifestyle.co.nz
            </a>
          </div>
          <div className="mb-gutter col-sm-6 mb-5 mb-sm-0 col-lg-3 text-center">
            <i className="fas mb-3 fa-2x fa-map-marker-alt"></i>
            <h5>Location</h5>
            <span>Lake Taupo region</span>
          </div>
          <div className="mb-gutter col-sm-6 col-lg-3 text-center">
            <i className="fas mb-3 fa-2x fa-clock"></i>
            <h5>Opening Hours</h5>
            <span>
              8am-5pm Monday to Friday
              <br />
              8am-12pm Saturday
            </span>
          </div>
        </div>
      </div>
    </section>

    {/* <section id="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198984.5422035483!2d175.76855069807704!3d-38.80649491906212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d6b93694c18b70b%3A0x2a00ef6165e1d880!2sLake%20Taupo!5e0!3m2!1sen!2snz!4v1619231920704!5m2!1sen!2snz"
        frameBorder={0}
        style={{ height: "100%", width: "100%", border: 0 }}
        allowFullScreen
        aria-hidden="false"
        title="Find us on Google maps"
      />
    </section> */}
  </Layout>
)

export default ContactPage
